import {
	reMissionStatusConstants
}from "./constants"

const initialState = {
	isLoading : false,
    reMissionStatusesList : null,
    reMissionStatusesPaged : null,
	reMissionStatusCRUDResult : {
		create : null,
		restore : null,
		update : null,
		delete : null
	},
    error : null
}

export default function (state = initialState, action){
	switch (action.type){
        //GetReMissionStatusesList
		case reMissionStatusConstants.GetReMissionStatusesList.REQUEST:
			return {
				...state,
				isLoading: true
			}
		case reMissionStatusConstants.GetReMissionStatusesList.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusesList : action.payload.result
			}
        case reMissionStatusConstants.GetReMissionStatusesList.FAILURE:
			return {
				...state,
				isLoading   : false,
			    error : action.payload.result
			}
        //GetReMissionStatusesPaged
        case reMissionStatusConstants.GetReMissionStatusesPaged.REQUEST:
			return {
				...state,
				isLoading: true
			}
		case reMissionStatusConstants.GetReMissionStatusesPaged.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusesPaged : action.payload.result
			}
        case reMissionStatusConstants.GetReMissionStatusesPaged.FAILURE:
			return {
				...state,
				isLoading   : false,
			    error : action.payload.result
			}
        //CreateReMissionStatus, RestoreReMissionStatus, UpdateReMissionStatus, DeleteReMissionStatus
        case reMissionStatusConstants.CreateReMissionStatus.REQUEST:
        case reMissionStatusConstants.RestoreReMissionStatus.REQUEST:
        case reMissionStatusConstants.UpdateReMissionStatus.REQUEST:
        case reMissionStatusConstants.DeleteReMissionStatus.REQUEST:
			return {
				...state,
				isLoading: true
			}
        case reMissionStatusConstants.CreateReMissionStatus.FAILURE:
        case reMissionStatusConstants.RestoreReMissionStatus.FAILURE:
        case reMissionStatusConstants.UpdateReMissionStatus.FAILURE:
        case reMissionStatusConstants.DeleteReMissionStatus.FAILURE:
			return {
				...state,
				isLoading   : false,
			    error : action.payload.result
			}
        case reMissionStatusConstants.CreateReMissionStatus.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusCRUDResult : { 
                    create: action.payload.result,
                    restore: null,
                    update: null,
                    delete: null,
                }
			}
        case reMissionStatusConstants.RestoreReMissionStatus.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusCRUDResult : { 
                    create: null,
                    restore: action.payload.result,
                    update: null,
                    delete: null,
                }
			}
        case reMissionStatusConstants.UpdateReMissionStatus.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusCRUDResult : { 
                    create: null,
                    restore: null,
                    update: action.payload.result,
                    delete: null,
                }
			}
        case reMissionStatusConstants.DeleteReMissionStatus.SUCCESS:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusCRUDResult : { 
                    create: null,
                    restore: null,
                    update: null,
                    delete: action.payload.result,
                }
			}
        //ClearReMissionStatusesValues
        case reMissionStatusConstants.ClearReMissionStatusesValues:
			return {
				...state,
				isLoading   : false,
			    reMissionStatusCRUDResult : { 
                    create: null,
                    restore: null,
                    update: null,
                    delete: null,
                }
			}
		default:
			return state
	}
}
